import { api } from ".";
import {
  CampaignPetFilter,
  CampaignPetStatus,
  ICampaign,
  ICampaignPet,
} from "../../domain/entities/Campaign";

const buildQueryParams = (filters: CampaignPetFilter): CampaignPetFilter => {
  const filteredEntries = Object.entries(filters).filter(
    ([, value]) => value !== ""
  );
  return Object.fromEntries(filteredEntries) as CampaignPetFilter;
};

export async function getCampaign(id: string): Promise<ICampaign | undefined> {
  return await api.get(`/api/v1/campaign/${id}`);
}

export async function postCampaign(data: ICampaign): Promise<void> {
  await api.post(`/api/v1/campaign/`, data);
}

export async function getAllCampaign(): Promise<ICampaign[] | undefined> {
  return await api.get(`/api/v1/campaign/`);
}

export async function patchRejectCampaign(id: string, data: string) {
  await api.patch(`/api/v1/campaign/cancel/${id}`, {
    canceled_reason: data,
  });
}

export async function getAllPetsByLocation(
  id: string,
  listType: string,
  filter: CampaignPetFilter
): Promise<ICampaignPet[] | undefined> {
  const params = buildQueryParams(filter);

  return await api.get(`/api/v1/campaign/pet`, {
    params: {
      ...params,
      campaign_pet_campaign_location_id: id,
      campaign_pet_status: listType === "queue" ? "AWAITING" : "IN_QUEUE",
    },
  });
}

export async function getAllPetsByLocationServiceProvider(
  id: string
): Promise<ICampaignPet[] | undefined> {
  return await api.get(`/api/v1/campaign/pet`, {
    params: {
      campaign_pet_campaign_location_id: id,
    },
  });
}

export async function sendToQueue(id: string): Promise<void> {
  await api.patch(`/api/v1/campaign/pet/status/${id}`, {
    status: "IN_QUEUE",
  });
}

export async function patchAttendenceTime(
  id: string,
  attendenceTime: string
): Promise<void> {
  await api.patch(`/api/v1/campaign/pet/time/${id}`, {
    attendence_time: attendenceTime,
  });
}

export async function postAttendenceTime(
  campaignLocationId: string,
  attendenceTime: string,
  petId: string
): Promise<void> {
  return await api.post(`/api/v1/campaign/pet`, {
    campaign_location_id: campaignLocationId,
    pet_id: petId,
    attendence_time: attendenceTime,
  });
}

export async function patchUpdateStatus(
  petId: string,
  status: keyof typeof CampaignPetStatus,
  reason?: string
): Promise<void> {
  return await api.patch(`/api/v1/campaign/pet/status/${petId}`, {
    status,
    reason,
  });
}
